import React, { lazy, Suspense } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import LazyLoader from "./components/LazyLoader/LazyLoader";

function App() {
  const Home = lazy(() => import("./components/Home/Home"));
  const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
  const FullBibDataViewer = lazy(() => import("./components/FullBibDataViewer/FullBibDataViewer"));
  const NotFound = lazy(() => import("./components/NotFound/NotFound"));

  return (
    // Routes
    <Suspense fallback={LazyLoader()}>
      <Routes>
        <Route exact path="/" element={<Home />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route exact path="/:id" element={<Home />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route exact path="bibtexView" element={<FullBibDataViewer />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default React.memo(App);

